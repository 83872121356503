import React, { useEffect } from 'react';
import {
  ImageGallery,
  WelcomePage,
  Birthday,
  Termine,
  MenuPlan,
  Losung,
  Weather,
  Obituary,
  Video,
  Zitate,
  Code,
  Iframe,
} from 'components';
import {
  selectElement,
  selectElementData,
  setElementCash,
  setOffline,
  t_calendarTermine, t_codeCash, t_iFrameCash,
  t_imageSliderCash, t_obituaryCash, t_videoCash,
  t_welcomeCash, t_zitateCash
} from 'store/playlist/store';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  FullcalendarEvent,
  Post as PostData,
  t_losung,
  t_weatherResponse
} from '@pixel-kraft/commulino-types';
import Post from 'components/Post/Post';
import { loadElement } from 'lib/elmentApi';
import { firestore } from 'lib/firebase';

type t_props = {
  elmId: string
}

const areEqual = (prev: t_props, next: t_props) => prev.elmId === next.elmId;

const RenderPlayElement = React.memo(({
  elmId
}: t_props) => {
  const elmData = useAppSelector((state) => selectElementData(state, elmId));
  const element = useAppSelector((state) => selectElement(state, elmId));
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!elmData && element) {
      loadElement(firestore, element) // Force cash update
        .then((data) => {
          if (data) {
            dispatch(setElementCash(data));
          }
        })
        .catch((e) => {
          console.log(e);
          dispatch(setOffline(true));
        });
    }
  }, [elmData, dispatch, element]);

  if (!elmData || !element) return null;
  switch (element.type) {
    case 'Willkommensseite':
    case 'Bild und Text': {
      const data = elmData as t_welcomeCash;
      return <WelcomePage text={data.text} img={data.img} id={elmId} fullScreen={data.fullScreen} />;
    }
    case 'Post in Kategorie': {
      const data = elmData as PostData;
      return <Post post={data} id={elmId} />;
    }
    case 'Bildergalerie': {
      const data = elmData as t_imageSliderCash;
      return (
        <ImageGallery
          imgDuration={data.imgDuration}
          images={data.images}
          start
          id={elmId}
        />
      );
    }
    case 'Geburtstag': {
      const data = elmData as FullcalendarEvent[][];
      return (
        <Birthday gbs={data} id={elmId} />
      );
    }
    case 'Termine': {
      const data = elmData as t_calendarTermine;
      return (
        <Termine
          events={data.calendar}
          day={data.day ?? 0}
          id={elmId}
        />
      );
    }
    case 'Speiseplan': {
      const data = elmData as FullcalendarEvent[][];
      return (
        <MenuPlan menu={data} id={elmId} />
      );
    }
    case 'Losung': {
      const data = elmData as t_losung[];
      return (
        <Losung losung={data} id={elmId} />
      );
    }
    case 'Wetter': {
      const data = elmData as t_weatherResponse;
      return (
        <Weather data={data} id={elmId} />
      );
    }
    case 'Todesanzeige': {
      const data = elmData as t_obituaryCash;
      return (
        <Obituary data={data} id={elmId} />
      );
    }
    case 'Video': {
      const data = elmData as t_videoCash;
      return (
        <Video url={data.url} waitForVideoToFinish={data.waitForVideoToFinish} id={elmId} />
      );
    }
    case 'Website': {
      const data = elmData as t_iFrameCash;
      return (
        <Iframe url={data.url} id={elmId} />
      );
    }
    case 'HTML Quelltext': {
      const data = elmData as t_codeCash;
      return (
        <Code code={data.code} id={elmId} />
      );
    }
    case 'Zitate': {
      const data = elmData as t_zitateCash;
      // return <Zitate zitate={data.zitate} id={elmId} img={data.img} />;
      return <Zitate id={elmId} img={data.img} />;
    }
    default: {
      // @ts-ignore
      console.log(element?.type);
      return null;
    }
  }
}, areEqual);
export default RenderPlayElement;
