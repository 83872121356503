import React from 'react';
import { Container } from 'components';

type t_props = {
  code: string,
  id: string,
}

const Code = ({
  code,
  id,
}: t_props) => (
  <Container
    id={id}
    innerStyle={{
    width: '100vw',
    paddingTop: '0px'
  }}
  >
    <iframe
      srcDoc={code}
      sandbox="allow-scripts"
      title="customHTML"
      style={{
        width: '100%',
        border: 'none',
        height: '100%'
      }}
    />
  </Container>
);
export default Code;
