const {default: logo} = require('./logo.png');
const {default: logoSvg} = require('./logo.svg');
const {default: logoLightSvg} = require('./logo-light.svg');

const config = {
	firebaseConfig: {
		apiKey: "AIzaSyAJlwzAWXCChRqW0ZmXDRuJwiNyZ5r5vyw",
		authDomain: "commulino-ronsdorf.firebaseapp.com",
		projectId: "commulino-ronsdorf",
		storageBucket: "commulino-ronsdorf.appspot.com",
		messagingSenderId: "925590949768",
		appId: "1:925590949768:web:c6f6dfb1039616d72db43d"
	},
	project: 'ronsdorf',
	appName: 'Ronsdorf',
	appStoreUrl: 'https://apps.apple.com/us/app/cs-ronsdorf/id1555435481',
	playStoreUrl: 'https://play.google.com/store/apps/details?id=de.commulino.ronsdorf',
	logo,
	logoSvg,
	logoLightSvg,
}

module.exports = config
