import React from 'react';
import { FullcalendarEvent } from '@pixel-kraft/commulino-types';
import { Container } from 'components';
import moment, { Moment } from 'moment';
import styles from './menu.module.css';
import menu0 from './images/menu0.jpg';
import menu1 from './images/menu1.jpg';
import menu2 from './images/menu2.jpg';

type t_props = {
  menu: FullcalendarEvent[][],
  id: string
}

const menuContent = (
  title: string,
  img: string|undefined
) => {
  // let newTitle = title;
  let newImg;

  if (title === '1' || title === '2') {
    title = `Menü ${title}`;
  }

  if (title.includes('1') && !img) {
    newImg = menu1;
  } else if (title.includes('2') && !img) {
    newImg = menu2;
  } else if (!img) {
    newImg = menu0;
  } else {
    newImg = img;
  }

  return (
    <>
      <p className={styles.menuTitle}>
        {title}
      </p>
      <div className={styles.menuImageContainer}>
        <img className={styles.menuImage} alt='404' src={newImg} />
      </div>
    </>
  );
};

const MenuPlan = ({
  menu,
  id
}: t_props) => {
  const getDay = (day: Moment) => {
    if (day.isBetween(moment(), moment().endOf('day'), 'day', '[]')) {
      return 'Heute';
    }
    return day.format('dd');
  };

  const renderMenu = (food: FullcalendarEvent[], i: number) => (
    <div key={i} className={styles.menuWrapper}>
      {menu.length > 1
        && <h1>{getDay(moment(food[0].start))}</h1>}
      <div className={styles.menuInner}>
        {food.map((f) => (
          <div key={f.id} className={styles.menu}>
            {menuContent(f.title, f.extendedProps.image)}
            {f.extendedProps.description
              && <p className={styles.menuName}>{f.extendedProps.description}</p>}
          </div>
        ))}
      </div>
    </div>
  );

  let menuAmountClass: string;
  if (menu.length === 1) {
    menuAmountClass = styles.todayMenu;
  } else if (menu.length === 3) {
    menuAmountClass = styles.threeDayMenu;
  } else if (menu.length === 5) {
    menuAmountClass = styles.fiveDayMenu;
  } else {
    menuAmountClass = styles.sevenDayMenu;
  }

  return (
    <Container id={id} image={`${process.env.PUBLIC_URL}/images/backgrounds/blackboard.jpg`}>
      <div className={menuAmountClass}>
        {menu.map(renderMenu)}
      </div>
    </Container>
  );
};
export default MenuPlan;
