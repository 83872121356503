import React from 'react';
import { t_losung } from '@pixel-kraft/commulino-types';
import { Container } from 'components';
import styles from './Losung.module.css';

type t_props = {
  losung: t_losung[],
  id: string
}

const Losung = ({ losung, id }: t_props) => (
  <Container id={id}>
    <div
      className={styles.innerContainer}
    >
      <p className={styles.headline}>Tageslosung</p>
      {losung.map((l) => (
        <div key={l.link}>
          <p className={styles.losung}>{l.losung}</p>
          <small className={styles.small}>{l.origin}</small>
        </div>
      ))}
      <div className={styles.footer}>
        © Evangelische Brüder-Unität – Herrnhuter Brüdergemeine
      </div>
    </div>
  </Container>
);

export default Losung;
