export function getInvertColor(color: string, bw: boolean = true) {
  const [, values] = color.match(/^rgba?\(([^)]*)\)/) ?? [];

  const [r, g, b] = values.split(',')
    .map((str: string): number => parseInt(str.trim()));

  if (bw) {
    return (Number(r) * 0.299 + Number(g) * 0.587 + Number(b) * 0.114) > 186 ? 'rgb(0,0,0)' : 'rgb(255,255,255)';
  }

  const nr = 255 - Number(r);
  const ng = 255 - Number(g);
  const nb = 255 - Number(b);

  return `rgb(${nr},${ng},${nb})`;
}

export function setCssVar(varName: string, value: string) {
  document.documentElement.style.setProperty(`--${varName}`, value);
}

export const setCssMainColor = (value: string) => {
  setCssVar('primaryColor', value);
  setCssVar('primaryTextColor', getInvertColor(value, true));
};

export const setCssSubColor = (value: string) => {
  setCssVar('secondaryColor', value);
  setCssVar('secondaryTextColor', getInvertColor(value, true));
};
