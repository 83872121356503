import React from 'react';
import { FullcalendarEvent } from '@pixel-kraft/commulino-types';
import { Container } from 'components';
import moment from 'moment';
import useWindowDimensions from 'lib/useWindowDimensions';
import styles from './birthday.module.css';

type t_props = {
  gbs: FullcalendarEvent[][],
  id: string
}

const Birthday = ({
  gbs,
  id
}: t_props) => {
  const renderGB = (gb: FullcalendarEvent) => (
    <div className={styles.birthday} key={gb.id}>
      {gb.extendedProps.image && <img className={styles.image} alt='404' src={gb.extendedProps.image} />}
      <div className={styles.name}>
        {gb.title}
      </div>
    </div>
  );

  const {
    width,
    height
  } = useWindowDimensions();

  const renderBd = (gb: FullcalendarEvent) => renderGB(gb);

  /* Returns true when next Birthdays */
  const nextBD = gbs[0].length === 0;
  const noContent = gbs[0].length === 0 && gbs[1].length === 0;

  const image = `${process.env.PUBLIC_URL}/images/backgrounds/${nextBD ? 'anst' : 'heut'}_geb_${width > height ? 'quer' : 'port'}.jpg`;

  return (
    <Container
      id={id}
      image={image}
    >
      {noContent ? (
        <p className={styles.soonDate}>Keine Geburtstage vorhanden</p>
      ) : (nextBD ? (
        /* NEXT BIRTHDAY(S) */
        <>
          <p className={styles.soonDate}>Am {moment(gbs[1][0].start).format('D. MMMM')}:</p>
          <div className={styles.soonBirthdays}>
            {gbs[1].map(renderBd)}
          </div>
        </>
      ) : (
        /* TODAY BIRTHDAY(S) */
        <>
          <div className={styles.todayBirthdays}>
            {gbs[0].map(renderBd)}
          </div>
          {/* <div className={styles.soonBirthdaysList}>
            {gbs.length > 1
              && (
              <>
     Nächste Geburtstage ({moment(gbs[1][0].start).format('D. MMMM')}): {gbs[1].map((bd, index) => (
                  <>
                    {bd.title}
                    {index === gbs[1].length - 1 ? '' : ', '}
                  </>
                ))}
              </>
            )}
          </div> */}
        </>
      ))}
    </Container>
  );
};
export default Birthday;
