/**
 * Function to load preload multiple images at once.
 *
 * Flotschi: Okay no idea why we have a function to load multiple urls here.
 * It will always be given a array with a single url in it...
 * @param urls
 */
export const preloadImages = (urls: string[]) => Promise.all(
  urls.map((url) => new Promise<void>((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve();
    };

    img.onerror = () => {
      // This will prevent the app from being stuck on the loading screen
      reject();
    };

    img.src = url;
  }))
);

const load = async (url: string) => {
    /**
     * Flotschi: Why are we converting a single URL to an array here???
     */
    await preloadImages([url]);
    return url;
};

export const imgLoad = async (url: string) => load(url);
