import React from 'react';
import { Container } from 'components';

type t_props = {
  url: string,
  id: string,
}

const Iframe = ({
  url,
  id,
}: t_props) => (
  <Container id={id} fullScreen innerStyle={{ width: '100vw', paddingTop: '0px' }}>
    <iframe src={url} title='iFrame' style={{ width: '100%', height: '100%' }} />
  </Container>
);
export default Iframe;
