import React from 'react';
import { Typo } from 'components';
import { Post } from '@pixel-kraft/commulino-types';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import { generateHTML } from '@tiptap/core';
import styles from './Content.module.css';

type t_props = {
  post: Post
}

const Content = ({ post }: t_props) => {
  if (typeof post.content === 'string') {
    return (
      <div className={`${styles.container}`}>
        <Typo style={styles.text}>{post.content}</Typo>
      </div>
    );
  }

  const __html = generateHTML(post.content, [
    StarterKit.configure({
      heading: false,
      horizontalRule: false,
      code: false,
      codeBlock: false
    }),
    Underline,
    Link.configure({
      openOnClick: false
    })
  ]);

  return (
    <div className={`${styles.container}`} dangerouslySetInnerHTML={{ __html }} />
  );
};
export default Content;
