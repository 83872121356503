import React, {
  useEffect, useRef, useState
} from 'react';
import { Typo } from 'components';
import useWindowDimensions from 'lib/useWindowDimensions';
import { Post } from '@pixel-kraft/commulino-types';
import styles from './Images.module.css';

type t_props = {
  post: Post
}

const Images = ({ post }: t_props) => {
  const { width } = useWindowDimensions();
  const Ref = useRef<HTMLDivElement>(null);
  const [myWidth, setMyWidth] = useState(0);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    if (Ref.current && post.images && post.images.length >= 5) {
      const s = Ref.current.offsetWidth;
      setMyWidth(s / 5 - 16);
    }
  }, [width, post.images]);

  if (post.images === undefined) return null;

  return (
    <div ref={Ref} className={styles.imagesMain}>
      <div
        className={styles.container}
        style={{
/*           height: '30vh', */
          opacity: load ? 0 : 1,
          justifyContent: 'center'
        }}
      >
        {post.images?.slice(0, 1)
          .map((image, index) => (
            <div
              key={`img${image.medium || image.original}`}
              className={styles.imageContainer}
/*               style={{
                height: '30vh',
                maxWidth: (post.images?.length ?? 0) >= 5 ? myWidth : '100%'
              }} */
            >
              <img
                src={image.medium || image.original}
                className={styles.image}
                alt=''
                onLoad={() => {
                  setLoad(false);
                }}
              />
              {index === 4 && (post.images?.length ?? 0) > 5 && (
                <div className={styles.more}>
                  <Typo style={styles.moreText}>+{(post.images?.length ?? 0) - 4}</Typo>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};
export default Images;
