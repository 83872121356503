import React, { useEffect } from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';
import { InfoScreen, NoDeviceID } from 'pages';
import { FirebaseListener } from 'components';

function Navigation() {
  return (
    <FirebaseListener>
      <Routes>
        <Route path='/' element={<NoDeviceID />} />
        <Route path='/:id' element={<InfoScreen />} />
        <Route path='/:id/preShow' element={<InfoScreen preShow />} />
      </Routes>
    </FirebaseListener>
  );
}

export default Navigation;
