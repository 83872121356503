import React from 'react';
import { Typo } from 'components';
import { Post } from '@pixel-kraft/commulino-types';
import styles from './Title.module.css';

type t_props = {
  post: Post
}

const Title = ({ post }: t_props) => (
  <div className={`${styles.title}`}>
    {/*     <Typo variant='h3' style={styles.title}>
      {post.title}
    </Typo> */}
    {post.title}
  </div>
);
export default Title;
