import React from 'react';
import { t_weatherResponse } from '@pixel-kraft/commulino-types';
import moment from 'moment/moment';
import { Container } from 'components';
import styles from './weather.module.css';

type t_props = {
  data: t_weatherResponse,
  id: string
}

type singleWeather = {
  day: number,
  name: string,
  night: number,
  weather: number,
}

// GETS ICON AND WEATHER-DESCRIPTION BY WEATHER-ID AND DAYTIME
function getByID(iconId: number, dth: number, careForNight: boolean) {
  let description: string;
  let image = '';

  // https://openweathermap.org/weather-conditions
  switch (iconId) {
    // Gewitter
    case 200:
    case 201:
    case 202:
    case 230:
    case 231:
    case 232:
      description = 'Gewitter mit Regen';
      image = 'storm_rain';
      break;

    case 210:
    case 211:
      description = 'Gewitter';
      image = 'storm_norain';
      break;

    case 212:
    case 221:
      description = 'Schweres Gewitter';
      image = 'storm_rain';
      break;

    // Regen
    case 300:
    case 301:
    case 302:
    case 310:
    case 311:
    case 321:
      description = 'Nieselregen';
      image = 'drizzle';
      break;

    case 312:
    case 500:
    case 520:
      description = 'Leichter Regen';
      image = 'light_rain';
      break;

    case 313:
    case 501:
    case 521:
      description = 'Regen';
      image = 'rain';
      break;

    case 314:
    case 502:
    case 503:
    case 504:
    case 522:
    case 531:
      description = 'Starker Regen';
      image = 'strong_rain';
      break;

    // Schneeregen
    case 612:
    case 615:
    case 620:
      description = 'Leichter Schneeregen';
      image = 'light_snow_rain';
      break;

    case 511:
    case 613:
    case 616:
    case 621:
      description = 'Schneeregen';
      image = 'snow_rain';
      break;

    case 622:
      description = 'Starker Schneeregen';
      image = 'strong_snow_rain';
      break;

    // Schnee
    case 600:
      description = 'Leichter Schnee';
      image = 'light_snow';
      break;

    case 601:
    case 611:
      description = 'Schnee';
      image = 'snow';
      break;

    case 602:
      description = 'Starker Schnee';
      image = 'strong_snow';
      break;

    // KLarer Himmel
    case 800:
      description = 'Klarer Himmel';
      if ((dth >= 21 || dth <= 6) && careForNight) {
        image = 'night';
      } else {
        image = 'day';
      }
      break;

    // Wolken
    case 801:
    case 802:
      description = 'Kaum Bewölkt';
      if ((dth >= 21 || dth <= 6) && careForNight) {
        image = 'light_cloudy_night';
      } else {
        image = 'light_cloudy_day';
      }
      break;

    case 803:
      description = 'Leicht Bewölkt';
      if ((dth >= 21 || dth <= 6) && careForNight) {
        image = 'cloudy_night';
      } else {
        image = 'cloudy_day';
      }
      break;

    case 804:
      description = 'Bewölkt';
      image = 'cloudy';
      break;

    // Sonstiges
    case 701:
    case 711:
    case 721:
    case 731:
    case 741:
    case 751:
    case 761:
    case 762:
      description = 'Nebel';
      image = 'fog';
      break;

    case 771:
      description = 'Windböen';
      image = 'wind';
      break;

    case 781:
      description = 'Starker Wind';
      image = 'strong_wind';
      break;

    default:
      description = 'ERROR';
  }

  return { description, image };
}

// CREATES SINGLE WEATHER ENTRIES
function SingleWeather({
  iconId,
  dth,
  ttemp,
  dtkey,
  now,
}: { iconId: number, dth: number, ttemp: number, dtkey: number, now: boolean }) {
  const careForNight = true;
  const { /* description, */ image } = getByID(iconId, dth, careForNight);

  const imageURL = `${process.env.PUBLIC_URL}/images/icons/${image}.svg`;

  return (
    <div className={styles.todayWeatherSingle} key={dtkey}>
      <p className={styles.time}>{ now ? 'Jetzt' : `${dth}:00 Uhr` }</p>
      <img alt="" src={imageURL} className={styles.todayIcon} />
      <p className={styles.todayDescription}>{/* {description} */}{ttemp}°C</p>
    </div>
  );
}

// CREATES WEATHER ENTRIES OF TODAY (15 HOURS / 5 WEATHERS)
function TodayWeather({ weather }: { weather: t_weatherResponse['list'] }) {
  const dtNow: number = weather[0].dt;

  return (
    <div className={styles.todayRow}>
      {weather.map((day) => (
        <SingleWeather
          iconId={day.weather[0].id}
          dth={parseInt(moment.unix(day.dt).utc().format('HH'), 10)}
          ttemp={Math.round(day.main.temp)}
          dtkey={day.dt}
          now={dtNow === day.dt}
        />
      ))}
    </div>
  );
}

// COMPARES ARRAY AND GETS THE MOST ARRAY ENTRIES
function mode(arr: Array<number>): number {
  return arr.sort(
    (a, b) => arr.filter((v) => v === a).length - arr.filter((v) => v === b).length
  ).pop() ?? 800;
}

// COMPARES DATA OF FOLLOWING DAYS
function followingDayComparing(array: t_weatherResponse['list'], isTomorrow: boolean): singleWeather {
  let dayName: string;
  const weatherIDs: Array<number> = [];
  const TempArr: Array<number> = [];

  array.forEach((threeH) => {
    TempArr.push(threeH.main.temp);
    weatherIDs.push(threeH.weather[0].id);
  });

  const dayTemp: number = Math.max.apply(null, TempArr);
  const nightTemp: number = Math.min.apply(null, TempArr);

  if (isTomorrow) {
    dayName = 'Morgen';
  } else {
    switch (moment.unix(array[0].dt).utc().isoWeekday()) {
      case 1: dayName = 'Montag'; break;
      case 2: dayName = 'Dienstag'; break;
      case 3: dayName = 'Mittwoch'; break;
      case 4: dayName = 'Donnerstag'; break;
      case 5: dayName = 'Freitag'; break;
      case 6: dayName = 'Samstag'; break;
      case 7: dayName = 'Sonntag'; break;
      default: dayName = 'ERROR';
    }
  }

  return {
    day: Math.round(dayTemp),
    night: Math.round(nightTemp),
    name: dayName,
    weather: mode(weatherIDs),
  };
}

// GET NEXT DAY, OR DAY IN GENERAL
function getDayPlus(dt: number, i: number) {
  return parseInt(moment.unix(dt).add(i, 'days').utc().format('DD'), 10);
}

// CREATES NEXT 3(i) DAYS OF WEATHER
function NextDaysWeather({ weather }: { weather: t_weatherResponse['list'] }) {
  const daysArrays: Array<singleWeather> = [];

  for (let i = 1; i < 4; i++) {
    const thatDayArray: t_weatherResponse['list'] = [];
    const thatDay = getDayPlus(weather[0].dt, i);

    weather.forEach((weatherEntry) => {
      if (getDayPlus(weatherEntry.dt, 0) === thatDay) {
        thatDayArray.push(weatherEntry);
      }
    });

    daysArrays.push(followingDayComparing(thatDayArray, (i === 1)));
  }

  return (
    <div className={styles.nextDays}>
      {daysArrays.map((day) => (
        <div className={styles.nextDaysWeatherSingle}>
          <p className={styles.nextDaysName}>{day.name}</p>
          <img className={styles.nextDaysIcon} alt='' src={`${process.env.PUBLIC_URL}/images/icons/${getByID(day.weather, 0, false).image}.svg`} />
          {/* <p className={styles.nextDaysWeather}>
          {getByID(day.weather, 0, false).description}</p> */}
          <p className={styles.nextDaysTemps}>{day.day}°C / {day.night}°C</p>
        </div>
      ))}
    </div>
  );
}

// CALCULATES WIND DIRECTION
function windDirection(deg: number) {
  let windDirectionString = '';

  if (deg > 337.5 || deg < 22.5) {
    windDirectionString = 'Nord';
  } else if (deg <= 337.5 && deg >= 292.5) {
    windDirectionString = 'Nord-West';
  } else if (deg <= 292.5 && deg >= 247.5) {
    windDirectionString = 'West';
  } else if (deg <= 247.5 && deg >= 202.5) {
    windDirectionString = 'Süd-West';
  } else if (deg <= 202.5 && deg >= 157.5) {
    windDirectionString = 'Süd';
  } else if (deg <= 157.5 && deg >= 112.5) {
    windDirectionString = 'Süd-Ost';
  } else if (deg <= 112.5 && deg >= 67.5) {
    windDirectionString = 'Ost';
  } else if (deg <= 67.5 && deg >= 22.5) {
    windDirectionString = 'Nord-Ost';
  }

  return windDirectionString;
}

// MAIN EXPORT
const Weather = ({ data, id }: t_props) => (
  <Container id={id} color='#ffffff'>
    <div className={styles.weatherContent}>
      <TodayWeather
        weather={data.list.slice(0, 5)}
      />
      <div className={styles.seeMoreRow}>
        <table className={styles.extraInfo}>
          <tbody>
            <tr>
              <td>Windgeschwindigkeit:</td>
              <td>
                {Math.round(data.list[0].wind.speed * 3.6)} km/h
              </td>
            </tr>
            <tr>
              <td>Windrichtung:</td>
              <td>
                {windDirection(data.list[0].wind.deg)} ({data.list[0].wind.deg}°)
              </td>
            </tr>
            <tr>
              <td>
                Luftfeuchtigkeit:
              </td>
              <td>
                {data.list[0].main.humidity}%
              </td>
            </tr>
          </tbody>
        </table>
        <NextDaysWeather
          weather={data.list}
        />
      </div>
    </div>
  </Container>
);

export default Weather;
