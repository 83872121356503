import { initializeApp } from 'firebase/app';
import { enableIndexedDbPersistence, getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { firebaseConfig, project } from 'config'; // Will be set with config-overrides.js

const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore();
  /* initializeFirestore(firebaseApp, {
  //experimentalAutoDetectLongPolling: true,
  experimentalForceLongPolling: true
}) */;

const storage = getStorage(firebaseApp);

console.info(firebaseConfig.projectId);

const auth = getAuth();

export async function signIn(deviceId: string) {
  const response = await fetch(`https://api.commulino.de/${project}/devices/${deviceId}/token`);
  const token = await response.text();

  await signInWithCustomToken(auth, token);
}

enableIndexedDbPersistence(firestore)
  .catch((err) => {
    if (err.code === 'failed-precondition') {
      console.warn('Close other tabs with this website');
    } else if (err.code === 'unimplemented') {
      console.error('Not supportet in this Browser');
    }
  });

export { firebaseApp, firestore, storage };
