import { Container } from 'components';
import React from 'react';
import './fonts/Vollkorn/Vollkorn-MediumItalic.woff2';
import './fonts/Vollkorn/Vollkorn-Regular.woff2';
import useWindowDimensions from 'lib/useWindowDimensions';
import moment from 'moment';
import styles from './zitate.module.css';
import quotes from './zitate.json';

type t_props = {
  // zitate: [string, string],
  id: string,
  img: number
}

// found here -> https://stackoverflow.com/questions/16801687/javascript-random-ordering-with-seed
function shuffle(array: Array<any>, seed: number) { // <-- ADDED ARGUMENT
  let m = array.length;
  let t;
  let i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(random(seed) * m--); // <-- MODIFIED LINE

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed; // <-- ADDED LINE
  }

  return array;
}

function random(seed: number) {
  const x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

const text = shuffle(quotes, moment().year())[parseInt(moment().format('DDD'), 10)];

const Zitate = ({
  // zitate,
  id,
  img
}: t_props) => {
  const {
    width,
    height
  } = useWindowDimensions();
  return (
    <Container
      id={id}
      image={`${process.env.PUBLIC_URL}/images/backgrounds/bg-zitat-${img}-${width > height ? 'quer' : 'hoch'}.jpg`}
      innerStyle={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <div>
        <div className={styles.zitate}>
          { text.quote }
        </div>
        <div className={styles.author}>
          { text.name }
        </div>
      </div>
    </Container>
  );
};

export default Zitate;
