// @ts-ignore
// eslint-disable-next-line import/no-unresolved
import { project } from 'config';
import moment, { Moment } from 'moment/moment';
import {
  FullcalendarEvent,
  SpeiseplanMode,
  t_losung,
  t_weatherResponse,
  TermineMode
} from '@pixel-kraft/commulino-types';
import {
 addDays, addMonths, eachDayOfInterval, endOfDay, isSameDay, startOfDay
} from 'date-fns';

const baseUrl = `https://api.commulino.de/${project}`;

// export const getCalendarUrl = (calendarId: string) => `${baseUrl}/calendars/${calendarId}`;

const request = async (path: string, method: string, body?: string) => {
  const headers: RequestInit['headers'] = {};

  if (body) {
    headers['Content-Type'] = 'application/json';
  }
  const res = await fetch(`${baseUrl}/${path}`, {
    method,
    headers,
    body
  });

  if (!res.ok) {
    throw Error(await res.text());
  }

  try {
    const json = await res.json();
    return json;
  } catch (err) {
    return true;
  }
};

export async function get<Type>(path: string) {
  return await request(path, 'GET') as Promise<Type>;
}

export async function post<Type>(path: string, body: any) {
  return await request(path, 'POST', JSON.stringify(body)) as Promise<Type>;
}

export const getEventList = async (calendarId: string, start: Moment, end: Moment) => {
  const format = 'YYYY-MM-DDTHH:mm:ssZ';
  return get<FullcalendarEvent[]>(`calendars/${calendarId}?start=${encodeURIComponent(start
    .format(format))}&end=${encodeURIComponent(end
    .format(format))}`);
};

const orderByStartDate = (a: FullcalendarEvent, b: FullcalendarEvent) => Math.sign(Date.parse(a.start!) - Date.parse(b.start!));

const filterGbs = (gbs: FullcalendarEvent[]) => {
  const erg: FullcalendarEvent[][] = [[], []];
  /*   const today = moment(gbs[0].start); */
  const today = moment()
    .startOf('day');
  let nextGb: Moment | undefined = undefined;

  for (let i = 0; i < gbs.length; ++i) {
    if (moment(gbs[i].start)
      .isSame(today, 'day')) {
      erg[0].push(gbs[i]);
    }
    if (nextGb !== undefined && moment(gbs[i].start)
      .isSame(nextGb, 'day')) {
      erg[1].push(gbs[i]);
    }
    if (nextGb === undefined && moment(gbs[i].start)
      .isAfter(today)) {
      nextGb = moment(gbs[i].start);
      erg[1].push(gbs[i]);
    }
    if (nextGb !== undefined && moment(gbs[i].start)
      .isAfter(nextGb)) {
      return erg;
    }
  }
  return erg;
};

/**
 * Loads the current and the next Birthday.
 * @param calendarId
 */
export const getGbs = async (calendarId: string) => {
  const start = moment()
    .startOf('day');
  const end = start.clone()
    .add(1, 'years');
  const rawData = await getEventList(calendarId, start, end);
  rawData.sort(orderByStartDate);

  return filterGbs(rawData);
};

/**
 * Loads the current events or the events of today+offsetDays
 * @param calendarId
 * @param mode day in the future that should be used
 */
export const getTermine = async (calendarId: string, mode: TermineMode = 0) => {
  let start = startOfDay(new Date());
  let end = endOfDay(start);

  switch (mode) {
    case TermineMode.TOMORROW: {
      start = addDays(start, 1);
      end = addDays(end, 1);
      break;
    }
    case TermineMode.FOLLOWING:
    case TermineMode.SINGLE: {
      // We only load the next 3 months of new events to not reach the limit of 1000 events
      end = addMonths(end, 3);
      break;
    }
    default:
  }

  const rawData = await getEventList(calendarId, moment(start), moment(end));
  rawData.sort(orderByStartDate);
  return rawData;
};

// The order for the MenuPlan
const menuOrder = [
  'menu',
  'dessert'
];

function getMenuOrder(str: string) {
  // Remove Umlaute and make lowercase
  const normalizedString = str.toLowerCase()
    .replace(/\u00e4/g, 'a')
    .replace(/\u00f6/g, 'o')
    .replace(/\u00fc/g, 'u');

  // Get Order from menuOrder
  const topOrder = menuOrder.findIndex((m) => normalizedString.startsWith(m));
  // Get Second Order by Number in Title e.g. Menu 1 or Desert 2
  const subOrder = parseInt(str.match(/\d+/)?.[0] ?? '0', 10);

  return [topOrder, subOrder];
}

function orderMenuPlan(a: FullcalendarEvent, b: FullcalendarEvent) {
  const [aTop, aSub] = getMenuOrder(a.title);
  const [bTop, bSub] = getMenuOrder(b.title);

  // Calculate Order
  return aTop - bTop || aSub - bSub;
}

export const getMenuPlan = async (
  calendarId: string,
  endDay: SpeiseplanMode = SpeiseplanMode.TODAY
): Promise<FullcalendarEvent[][]> => {
  // For everything other than the today view skip first day
  const start = startOfDay(endDay > SpeiseplanMode.TODAY ? addDays(new Date(), 1) : new Date());
  const end = endOfDay(addDays(start, Math.max(endDay - 1, 0)));

  const events = await getEventList(calendarId, moment(start), moment(end));
  const sortedEvents = events.sort(orderMenuPlan);

  // Get the list of days for the range, and map the food to it.
  return eachDayOfInterval({
    start,
    end
  })
    .map((day) => sortedEvents.filter(({ start: evStart }) => isSameDay(day, new Date(evStart!))));
};

export const getLosung = async () => {
  const date = moment()
    .startOf('day'); // Is fix add Day 1 needed?
  return post<t_losung[]>('infosystem/losung', {
    year: date.year(),
    day: date.dayOfYear()
  });
};

export const getZitate = async () => get<string[]>('zitate/');

export const getWeather = async (lat: number, lon: number) => get<t_weatherResponse>(`infosystem/weather?lat=${lat}&lon=${lon}`);
