import React, { useEffect } from 'react';
import { project } from 'config';
import { getAuth, signInWithCustomToken, signOut } from 'firebase/auth';
import { t_postTodoListMessage } from '@pixel-kraft/commulino-types';
import { useAppDispatch } from 'store/hooks';
import { setLoadToken } from 'store/playlist/store';

const IframeMessages = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const onMsg = (event: MessageEvent<t_postTodoListMessage>) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(event);
      }
      //if (event.origin !== `https://commulino-${project}.web.app` && event.origin !== 'http://localhost:3000') return;
      switch (event.data.type) {
        case 'loadToken':
          dispatch(setLoadToken(true));
          console.log("Infosystem","Wait for Token")
          break;
        case 'token':
          signInWithCustomToken(getAuth(), event.data.token)
            .catch(() => signOut(getAuth()))
            .finally(() => {
              dispatch(setLoadToken(false))
              console.log("Infosystem","Login END")
            });
          break;
        default:
          console.log(event);
      }
    };
    window.addEventListener('message', onMsg);
    return () => {
      window.removeEventListener('message', onMsg);
    };
  }, []);

  return <></>;
};

export default IframeMessages;
