import { Firestore } from 'firebase/firestore';
import { getLatestPostInCategory } from 'lib/postApi';
import {
  getGbs,
  getLosung,
  getMenuPlan,
  getTermine,
  getWeather, getZitate
} from 'lib/eventApi';
import { imgLoad } from 'lib/images';
import { t_elementType } from 'store/playlist/store';
import { t_playElement } from 'lib/types';

export const loadElement = async (
  firestore: Firestore,
  elm: t_playElement,
  lastUpdate?: number
): Promise<{ id: string, data: t_elementType } | undefined> => {
  // If element was update in the last 5 minutes don't update.
  // Can be forced to update if not lastUpdate is given.
  if (lastUpdate && lastUpdate + 300000 > Date.now()) return undefined;
  try {
    switch (elm.type) {
      // @ts-ignore
      case 'Willkommensseite':
      case 'Bild und Text': {
        let img: string | undefined = undefined;
        if (elm.value.img?.url) {
          img = await imgLoad(elm.value.img.url);
        }

        return {
          id: elm.id,
          data: {
            text: elm.value.text,
            img,
            fullScreen: elm.value.fullScreen,
          }
        };
      }
      case 'Post in Kategorie': {
        const data = await getLatestPostInCategory(firestore, elm.value.categoryId, elm.value.post);
        return {
          id: elm.id,
          data
        };
      }
      case 'Bildergalerie': {
        const images = await Promise.all(elm.value.images.map(async ({
          url,
          id
        }) => ({
          id,
          url: await imgLoad(url)
        })));

        return {
          id: elm.id,
          data: {
            imgDuration: elm.value.imgDuration,
            images
          }
        };
      }
      case 'Geburtstag': {
        const data = await getGbs(elm.value.calendarId);
        return {
          id: elm.id,
          data
        };
      }
      case 'Termine': {
        const data = await getTermine(elm.value.calendarId, elm.value.nextDay);
        return {
          id: elm.id,
          data: {
            calendar: data,
            day: elm.value.nextDay
          }
        };
      }
      case 'Speiseplan': {
        const data = await getMenuPlan(elm.value.calendarId, elm.value.nextDays);
        return {
          id: elm.id,
          data
        };
      }
      case 'Losung': {
        // 5min * 12 = 60 Update every hour.
        if (lastUpdate && lastUpdate + (300000 * 12) > Date.now()) return undefined;
        const data = await getLosung();
        return {
          id: elm.id,
          data
        };
      }
      case 'Wetter': {
        if (lastUpdate && lastUpdate + (300000 * 12) > Date.now()) return undefined;
        const data = await getWeather(elm.value.lat, elm.value.lon);
        return {
          id: elm.id,
          data
        };
      }
      case 'Todesanzeige': {
        let photo: string | undefined = undefined;
        if (elm.value.photo) {
          photo = await imgLoad(elm.value.photo);
        }
        return {
          id: elm.id,
          data: {
            ...elm.value,
            photo
          }
        };
      }
      case 'Video': {
        return {
          id: elm.id,
          data: elm.value
        };
      }
      case 'Zitate': {
        return {
          id: elm.id,
          data: {
            // zitate: [data[0], data[1]],
            zitate: ['', ''],
            img: Math.floor(Math.random() * 3)
          }
        };
      }
      case 'Website': {
        return {
          id: elm.id,
          data: {
            url: elm.value.url
          }
        };
      }
      case 'HTML Quelltext': {
        return {
          id: elm.id,
          data: {
            code: elm.value.code
          }
        };
      }
      default: {
        // @ts-ignore
        console.warn(`Type not known! elementApi ${elm.type}`);
        return undefined;
      }
    }
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      console.error(e);
    }
    throw Error('Cant load Element');
  }
};
