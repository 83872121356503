import React from 'react'

interface Props {
	children: React.ReactNode
	variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
	strong?: boolean
	br?: boolean
	style?: string
}

const Typo = ({ children, variant, strong, br, style }: Props) => {
	let fontSize = 14
	let fontWeight = 400
	let color = '#172b4d'
	let marginBottom = 0

	switch (variant) {
		case 'h1':
			break
		case 'h2':
			break
		case 'h3':
			fontSize = 21
			fontWeight = 600
			color = '#505d71'
			marginBottom = 12
			break
		case 'h4':
			fontSize = 17
			fontWeight = 600
			color = '#505d71'
			marginBottom = 4
			break
		case 'h5':
			fontSize = 15
			fontWeight = 500
			marginBottom = 4
			break
		default:
			break
	}

	if (strong) {
		fontWeight = 600
	}

	return (
		<React.Fragment>
			<div
				style={{
					display: 'inline-block',
					whiteSpace: 'pre-wrap',
					fontSize,
					fontWeight,
					color,
					marginBottom
				}}
				className={style}
			>
				{children}
			</div>
			{br && <br />}
		</React.Fragment>
	)
}

export default Typo
