import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  FullcalendarEvent,
  Post,
  t_PlayListData,
  t_losung,
  t_weatherResponse,
  i_obituary,
  i_video,
  i_code,
  i_frame,
  TermineMode,
} from '@pixel-kraft/commulino-types';
import { RootState } from 'store/store';
import { deepDiffMapper, getErgOfCheck } from 'lib/helpers';
import { t_playElement } from 'lib/types';

export type t_welcomeCash = { text: string, img?: string, fullScreen?: boolean, };
export type t_imageSliderCash = { imgDuration: number, images: { id: string, url: string }[] };
export type t_calendarTermine = { calendar: FullcalendarEvent[], day?: TermineMode };
export type t_obituaryCash = i_obituary['value'];
export type t_videoCash = i_video['value'];
export type t_iFrameCash = i_frame['value'];
export type t_codeCash = i_code['value'];
export type t_zitateCash = { zitate: [string, string], img: number };

export type t_elementType =
  Post
  | t_welcomeCash
  | t_imageSliderCash
  | FullcalendarEvent[][]
  | FullcalendarEvent[]
  | t_calendarTermine
  | t_losung[]
  | t_weatherResponse
  | t_obituaryCash
  | t_iFrameCash
  | t_codeCash
  | t_videoCash
  | t_zitateCash;

type t_elementCash = {
  lastUpdate: number
  element: t_elementType
}

type t_playListStore = {
  deviceId?: string,
  playListId?: string,
  playlist?: t_PlayListData,
  elements: t_playElement[],
  loading: boolean,
  offline: boolean,
  aktive: number,
  noPlayList?: boolean,
  elementCash: { [id: string]: t_elementCash },
  loadToken: boolean
}

const initialState: t_playListStore = {
  elements: [],
  loading: false,
  offline: false,
  aktive: -1,
  elementCash: {},
  loadToken: true
};

export const playListSlice = createSlice({
  name: 'playList',
  initialState,
  reducers: {
    requestData: (state) => {
      state.loading = true;
    },
    setPlayListId: (state, action: PayloadAction<string>) => {
      // Reload site for clean start
      if (state.playListId && state.playListId !== action.payload) window.location.reload();
      state.noPlayList = false;
      state.playListId = action.payload;
    },
    setPlaylist: (state, action: PayloadAction<t_PlayListData>) => {
      state.playlist = action.payload;
    },
    setElements: (state, action: PayloadAction<t_playElement[]>) => {
      state.elements = action.payload;
    },
    modifyElement: (state, action: PayloadAction<t_playElement>) => {
      const elm = state.elements.filter(({ id }) => id === action.payload.id);
      if (elm.length === 1) {
        const e = elm[0].value;
        const erg = deepDiffMapper.map({ test: e }, { test: action.payload.value });
        if (elm[0].type !== action.payload.type || !getErgOfCheck(Object.keys(erg), erg)) {
          delete state.elementCash[action.payload.id];
          state.elements[state.elements.indexOf(elm[0])] = action.payload;
        }
      }
    },
    removeElement: (state, action: PayloadAction<string>) => {
      const elm = state.elements.filter(({ id }) => id === action.payload);
      if (elm.length === 1) state.elements.splice(state.elements.indexOf(elm[0]), 1);
      delete state.elementCash[action.payload];
    },
    loadDataFinish: (state) => {
      state.loading = false;
    },
    setOffline: (state, action: PayloadAction<boolean>) => {
      state.offline = action.payload;
    },
    next: (state) => {
      state.aktive = (state.aktive + 1) % state.elements.length;
    },
    before: (state) => {
      const copy = state.aktive - 1;
      if (copy < 0) {
        state.aktive = state.elements.length - 1;
      } else {
        state.aktive = copy;
      }
    },
    start: (state) => {
      console.log('Try Start');
      if (state.aktive < 0) {
        console.log('Start');
        state.aktive = 0;
      }
    },
    stop: (state) => {
      if (state.aktive >= 0) {
        state.aktive = -1;
      }
    },
    setDeviceId: (state, action: PayloadAction<string | undefined>) => {
      state.deviceId = action.payload;
    },
    setElementCash: (state, action: PayloadAction<{ id: string, data: t_elementType }>) => {
      state.elementCash[action.payload.id] = {
        element: action.payload.data,
        lastUpdate: Date.now()
      };
      state.offline = false;
    },
    setNoPlayList: (state, action: PayloadAction<boolean>) => {
      state.noPlayList = action.payload;
      // Reset data if notPlayList true
      if (action.payload) {
        state.playListId = undefined;
        state.playlist = undefined;
        state.aktive = -1;
        state.elements = [];
        state.elementCash = {};
      }
    },
    setLoadToken: (state, action: PayloadAction<boolean>) => {
      state.loadToken = action.payload;
    }
  }
});
export const {
  requestData,
  setElements,
  removeElement,
  modifyElement,
  setPlaylist,
  loadDataFinish,
  setOffline,
  setPlayListId,
  next,
  start,
  stop,
  setDeviceId,
  setElementCash,
  setNoPlayList,
  before,
  setLoadToken
} = playListSlice.actions;

export const selectPlayList = (state: RootState) => state.playList.playlist;
export const selectElements = (state: RootState) => state.playList.elements;
export const selectLoading = (state: RootState) => state.playList.loading;
export const selectLoadToken = (state: RootState) => state.playList.loadToken;
export const selectOffline = (state: RootState) => state.playList.offline;
export const selectPlayListId = (state: RootState) => state.playList.playListId;
export const selectNoPlayList = (state: RootState) => state.playList.noPlayList;
export const selectNextElem = (state: RootState) => (state.playList.aktive >= 0
  && state.playList.elements.length > 0
    ? state.playList.elements[(state.playList.aktive + 1) % state.playList.elements.length]
    : undefined
);
export const selectAktive = (state: RootState) => (state.playList.aktive >= 0
  && state.playList.elements.length > 0
    ? state.playList.elements[state.playList.aktive]
    : undefined
);
export const selectActiveElementData = (state: RootState) => {
  const act = selectAktive(state);
  if (!act) return undefined;
  return state.playList.elementCash[act.id].element;
};
export const selectNextElementData = (state: RootState) => {
  const nextElm = selectNextElem(state);
  return nextElm ? state.playList.elementCash[nextElm.id].element : undefined;
};
export const selectNextElementLastUpdate = (state: RootState) => {
  const nextElm = selectNextElem(state);
  return nextElm ? state.playList.elementCash[nextElm.id]?.lastUpdate : undefined;
};
export const selectElementData = (state: RootState, id: string | undefined) => (
  id && (id in state.playList.elementCash) ? state.playList.elementCash[id].element : undefined);
export const selectDeviceId = (state: RootState) => state.playList.deviceId;
export const isRunning = (state: RootState) => state.playList.aktive >= 0;
export const selectElement = (state: RootState, id: string) => {
  const elms = state.playList.elements.filter(({ id: _id }) => _id === id);
  if (elms.length !== 1) return undefined;
  return elms[0];
};

export default playListSlice.reducer;
