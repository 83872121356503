import React, { useEffect, useState } from 'react';
import styles from 'components/Header/header.module.css';
import moment from 'moment';

const Clock: React.FC = () => {
  const [time, setTime] = useState(new Date());
  useEffect(() => {
    const timerID = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(timerID);
  }, []);

  const currentTime = `${String(time.getHours()).padStart(2, '0')}:${String(time.getMinutes()).padStart(2, '0')}`;
  const minuteDeg = time.getMinutes() * 6;
  const hourDeg = (time.getHours() * 30) + (time.getMinutes() * 0.5);

  return (
    <div className={styles.time}>
      <svg className={styles.uhr} xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
        <g className={styles.uhrkreis}>
          <path xmlns="http://www.w3.org/2000/svg" d="M48.5 25C48.5 37.9787 37.9787 48.5 25 48.5C12.0213 48.5 1.5 37.9787 1.5 25C1.5 12.0213 12.0213 1.5 25 1.5C37.9787 1.5 48.5 12.0213 48.5 25Z" strokeWidth="3" />
        </g>

        {/* === GROSSER ZEIGER === */}
        <g className={styles.zeiger} style={{ transform: `rotate(${minuteDeg}deg)` }}>
          <path xmlns="http://www.w3.org/2000/svg" d="M26.5 8.5C26.5 7.67157 25.8284 7 25 7C24.1716 7 23.5 7.67157 23.5 8.5V25C23.5 25.8284 24.1716 26.5 25 26.5C25.8284 26.5 26.5 25.8284 26.5 25V8.5Z" />
        </g>

        {/* === KLEINER ZEIGER === */}
        <g className={styles.zeiger} style={{ transform: `rotate(${hourDeg}deg)` }}>
          <path xmlns="http://www.w3.org/2000/svg" d="M26.5 12.8125C26.5 11.9841 25.8284 11.3125 25 11.3125C24.1716 11.3125 23.5 11.9841 23.5 12.8125V25C23.5 25.8284 24.1716 26.5 25 26.5C25.8284 26.5 26.5 25.8284 26.5 25V12.8125Z" />
        </g>
      </svg>

      <div className={styles.timeWrapper}>
        <span className={styles.dateString}>
          {moment(time).format('dd DD.MM')}
        </span>
        <span className={styles.timeString}>
          {currentTime}
        </span>
      </div>
    </div>
  );
};

export default Clock;
