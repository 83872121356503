import React, { ReactNode } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectAktive } from 'store/playlist/store';
import styles from './container.module.css';

type slideProps = {
  children?: ReactNode,
  id: string,
  style?: React.CSSProperties,
  image?: string,
  imageBefore?: string,
  color?: string,
  extraClasses?: string,
  innerClass?: string,
  innerStyle?: React.CSSProperties,
  fullScreen?: boolean,
};

export default function Container({
  children,
  id,
  style,
  image,
  imageBefore,
  color,
  extraClasses,
  innerClass,
  innerStyle,
  fullScreen,
}: slideProps) {
  const aktivCheck = useAppSelector(selectAktive);

  let classes = `${styles.slide} `;

  if (aktivCheck) {
    if (aktivCheck.id === id) {
      classes += `${styles.active} `;
    }
  }

  if (extraClasses) {
    classes += `${extraClasses} `;
  }

  // if (nextElm) {
  //   if (nextElm.animation === 'slide') {
  //     classes += `${sliding.slideover} `;
  //   } else {
  //     classes += `${sliding.slidefade} `;
  //   }
  // }

  const backgroundImagesArray = [
    ...(imageBefore ? [`url("${imageBefore}")`] : []),
    ...(image ? [`url("${image}")`] : []),
  ];

  return (
    <div
      className={classes}
      style={{
        ...style,
        backgroundColor: color || 'var(--primaryColor,#000)',
        backgroundImage: backgroundImagesArray.join(','),
        overflow: 'hidden',
        backgroundSize: fullScreen === undefined || fullScreen ? 'cover' : 'contain',
      }}
    >
      <div
        style={innerStyle}
        className={`${styles.sliderInner} ${innerClass ?? ''}`}
      >
        {children}
      </div>
    </div>
  );
}
