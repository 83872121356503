// https://stackoverflow.com/questions/8572826/generic-deep-diff-between-two-objects
export const deepDiffMapper = (function () {
  return {
    VALUE_CREATED: 'created',
    VALUE_UPDATED: 'updated',
    VALUE_DELETED: 'deleted',
    VALUE_UNCHANGED: 'unchanged',
    map(obj1: { [x: string]: any; } | undefined, obj2: { [x: string]: any; }) {
      let key;
      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw Error('Invalid argument. Function given, object expected.');
      }
      if (this.isValue(obj1) || this.isValue(obj2)) {
        return {
          type: this.compareValues(obj1, obj2),
          data: obj1 === undefined ? obj2 : obj1
        };
      }

      const diff: { [key: string]: any } = {};
      for (key in obj1) {
        if (this.isFunction(!(obj1) || obj1[key])) {
          continue;
        }

        let value2 = undefined;
        if (obj2[key] !== undefined) {
          value2 = obj2[key];
        }

        // @ts-ignore
        diff[key] = this.map(obj1[key], value2);
      }
      for (key in obj2) {
        // @ts-ignore
        if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
          continue;
        }

        // @ts-ignore
        diff[key] = this.map(undefined, obj2[key]);
      }

      return diff;
    },
    compareValues(value1: any | undefined, value2: any | undefined) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED;
      }
      if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
        return this.VALUE_UNCHANGED;
      }
      if (value1 === undefined) {
        return this.VALUE_CREATED;
      }
      if (value2 === undefined) {
        return this.VALUE_DELETED;
      }
      return this.VALUE_UPDATED;
    },
    isFunction(x: any) {
      return Object.prototype.toString.call(x) === '[object Function]';
    },
    isArray(x: any) {
      return Object.prototype.toString.call(x) === '[object Array]';
    },
    isDate(x: any) {
      return Object.prototype.toString.call(x) === '[object Date]';
    },
    isObject(x: any) {
      return Object.prototype.toString.call(x) === '[object Object]';
    },
    isValue(x: any) {
      return !this.isObject(x) && !this.isArray(x);
    }
  };
}());

/**
 * Check the return of deepDiffMapper
 * @param arr
 * @param erg
 * @return true if no change else false
 */
export const getErgOfCheck = (arr: any[], erg: { [p: string]: any }): boolean => {
  if (!erg) return true;
  for (let i = 0; i < arr.length; ++i) {
    if ('type' in erg[arr[i]]) {
      if (erg[arr[i]].type !== 'unchanged') {
        return false;
      }
    } else if (!getErgOfCheck(Object.keys(erg[arr[i]]), erg[arr[i]])) {
        return false;
      }
  }
  return true;
};
