import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Config_Color } from '@pixel-kraft/commulino-types';
import { RootState } from 'store/store';
import { setCssMainColor, setCssSubColor } from 'lib/color';

type t_configStore = Config_Color;

const initialState: t_configStore = {
  mainColor: 'rgba(179,179,179,1)',
  subColor: 'rgba(0,82,204,1)'
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setColors: (state, action: PayloadAction<Config_Color>) => {
      state.mainColor = action.payload.mainColor;
      state.subColor = action.payload.subColor;
      setCssMainColor(action.payload.mainColor);
      setCssSubColor(action.payload.subColor);
    },
    setMainColor: (state, action: PayloadAction<Config_Color['mainColor']>) => {
      state.mainColor = action.payload;
      setCssMainColor(action.payload);
    },
    setSubColor: (state, action: PayloadAction<Config_Color['subColor']>) => {
      state.subColor = action.payload;
      setCssSubColor(action.payload);
    }
  }
});

export const {
  setMainColor,
  setSubColor,
  setColors
} = configSlice.actions;

export const selectColors = (state: RootState) => ({
  subColor: state.config.subColor,
  mainColor: state.config.subColor
});

export const selectMainColor = (state: RootState) => state.config.mainColor;
export const selectSubColor = (state: RootState) => state.config.subColor;

export default configSlice.reducer;
