import React from 'react';
import { t_obituaryCash } from 'store/playlist/store';
import { Container } from 'components';
import moment from 'moment';
import useWindowDimensions from 'lib/useWindowDimensions';
import { v4 as uuid4 } from 'uuid';
import styles from './Orbituary.module.css';

type t_props = { id: string, data: t_obituaryCash }

const Obituary = ({
  id,
  data: {
    name,
    photo,
    date,
    text,
    background
  }
}: t_props) => {
  const {
    width,
    height
  } = useWindowDimensions();

  const image = `${process.env.PUBLIC_URL}/images/backgrounds/${background}-tod-${width > height ? 'quer' : 'hoch'}.jpg`;

  return (
    <Container
      id={id}
      image={image}
      extraClasses={styles.obituarySlideBg}
    >
      <div className={`${styles.obituaryWrapper} ${photo ? '' : styles.noImage}`}>
        <div className={styles.obituary}>
          {photo && <img className={styles.obituaryImage} alt='404' src={photo} />}
          <div className={styles.obituaryName}>
            <div>
              <div className={styles.name}>{name}</div>
              <div className={styles.date}>{moment(date)
                .format('D. MMMM YYYY')}
              </div>
            </div>
            <div className={styles.extraText}>
              {text ? text.split('\n')
                .map((textPart) => <p key={uuid4()} style={{ margin: 0 }}>{textPart}</p>) : ''}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Obituary;
