import React, { useEffect, useState } from 'react';
import { selectAktive, t_welcomeCash } from 'store/playlist/store';
import { Container } from 'components';
import { useAppSelector } from 'store/hooks';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from './WelcomePage.module.css';
import '../../animation.css';

type t_props = t_welcomeCash & {
  style?: React.CSSProperties,
  className?: string,
  id: string,
  fullScreen?: boolean,
};

const WelcomePage = ({
  img,
  text,
  style,
  className,
  id,
  fullScreen,
}: t_props) => {
  const active = useAppSelector(selectAktive)!;
  const [elms, setElms] = useState<{ title: string, ref: React.RefObject<HTMLHeadingElement> }[]>([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (text) {
        setElms(text.split('\n')
          .map((title) => ({
            title,
            ref: React.createRef<HTMLHeadingElement>()
          })));
      }
    }, 1000); // Wait of enter animation to end

    return () => {
      clearTimeout(timer);
    };
  }, [text]);

  return (
    <Container
      id={id}
      style={{
        ...style
      }}
      image={img || ''}
      fullScreen={fullScreen}
    >
      <div className={styles.titleWrapp}>
        <TransitionGroup>
          {elms.map(({
            title,
            ref
          }, index) => (
            <CSSTransition
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              classNames={`${active.animation}in`}
              timeout={500 + index * 250}
              nodeRef={ref}
            >
              <h1
                ref={ref}
                className={`${styles.title}`}
                style={{
                  transitionDelay: `${index * 250}ms`
                }}
              >
                {title}
              </h1>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </Container>
  );
};
export default WelcomePage;
