import React, { useEffect, useRef, useState } from 'react';
import { selectNextElem, t_imageSliderCash } from 'store/playlist/store';
import { Container } from 'components';
import { useAppSelector } from 'store/hooks';
import styles from './ImageGallery.module.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { selectTrigger } from 'store/timer/stroe';
import '../../animation.css';

type t_props = t_imageSliderCash & {
  start: boolean,
  id: string
};

const ImageGallery = ({
  start,
  images,
  imgDuration,
  id
}: t_props) => {
  const [active, setActive] = useState(0);
  const trigger = useAppSelector(selectTrigger);
  const nextElm = useAppSelector(selectNextElem);
  const refs = images.map(() => React.createRef<HTMLImageElement>());
  const timer = useRef(imgDuration);

  useEffect(() => {
    if (start) {
      if (timer.current <= 0) {
        setActive((prev) => (prev + 1) % images.length);
        timer.current = imgDuration;
      } else
        timer.current -= 1000;
    }
  }, [start, trigger, images, imgDuration]);

  return (
    <Container
      id={id}
      innerStyle={{
        width: '100vw',
        height: 'calc(100vh - 150px)',
        paddingTop: 'unset',
        margin: 'unset',
        paddingBottom: 'unset',
        bottom: '0'
      }}
    >
      <TransitionGroup>
        {nextElm
          && (
            <CSSTransition
              key={images[active].id}
              nodeRef={refs[active]}
              timeout={1000}
              classNames={`${nextElm.animation}in`}
            >
              <img
                ref={refs[active]}
                src={images[active].url}
                alt='active'
                className={styles.img}
              />
            </CSSTransition>
          )}

        {images.length > 1
          && (
            <div className={styles.indicatorWrapper}>
              {images.map((image, index) => (
                <div
                  key={image.id}
                  className={(index === active) ? styles.indicatorDotActive : styles.indicatorDot}
                />
              ))}
            </div>
          )}
      </TransitionGroup>
    </Container>
  );
};
export default ImageGallery;
