import React from 'react';
import styles from './noDeviceID.module.css';

const NoDeviceID = () => (
  <div className={styles.noDeviceBackground}>
    <h1 className={styles.noDeviceTitle}>Es wurde keine Geräte ID angegeben!</h1>
  </div>
);

export default NoDeviceID;
