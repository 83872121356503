import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';

type t_timerStore = {
  trigger: boolean,
  pause: boolean,
}

const initialState: t_timerStore = {
  trigger: false,
  pause: false
};

const timerSlice = createSlice({
  name: 'timer',
  initialState,
  reducers: {
    triggerChange: (state) => {
      if (!state.pause) {
        state.trigger = !state.trigger;
      }
    },
    pause: (state) => {
      state.pause = true;
    },
    resume: (state) => {
      state.pause = false;
    }
  }
});

export const {
  triggerChange,
  pause,
  resume
} = timerSlice.actions;

export const selectTrigger = (state: RootState) => state.timer.trigger;
export const isPause = (state: RootState) => state.timer.pause;

export default timerSlice.reducer;
