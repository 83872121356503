import React, {
  lazy,
  useEffect,
  useState,
  Suspense
} from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  t_elementType,
  before,
  isRunning,
  next,
  selectAktive,
  selectElements, selectLoadToken,
  selectNextElem,
  selectNextElementLastUpdate, selectNoPlayList, selectOffline,
  setDeviceId,
  setElementCash, setOffline, setPlayListId
} from 'store/playlist/store';
import {
  RenderPlayElement,
  Row,
  CommuLinoLoading,
  Banner,
  ActivityIndicator,
  IframeMessages
} from 'components';
import { useParams } from 'react-router-dom';
import { firestore, signIn } from 'lib/firebase';
import { loadElement } from 'lib/elmentApi';
import { pause, resume, selectTrigger } from 'store/timer/stroe';
import { FiChevronLeft, FiChevronRight, FiInfo } from 'react-icons/fi';
import { Header } from 'components/Header/header';
import { t_playElement } from 'lib/types';
import {
  FullcalendarEvent,
  t_animation,
  t_calendar,
  i_Geburtstag
} from '@pixel-kraft/commulino-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styles from './InfoScreen.module.css';
import '../../animation.css';

type t_props = {
  preShow?: boolean
}

/**
 * Render bei jeder änderung von time. Also jede Sekunde, es wird aber nur die Virtuelle
 * DOM updated, erste wen sich ein gerenderte Element ändert wird die tatsächliche DOM update.
 * https://stackoverflow.com/questions/24718709/reactjs-does-render-get-called-any-time-setstate-is-called
 */
const InfoScreen = ({ preShow }: t_props) => {
  const elements = useAppSelector(selectElements);
  const aktiveElement = useAppSelector(selectAktive);
  const nextElm = useAppSelector(selectNextElem);
  const lastUpdate = useAppSelector(selectNextElementLastUpdate);
  const trigger = useAppSelector(selectTrigger);
  const offline = useAppSelector(selectOffline);
  const run = useAppSelector(isRunning);
  const noPlayList = useAppSelector(selectNoPlayList);
  const params = useParams();
  const dispatch = useAppDispatch();
  // const [timer, setTimer] = useState<number | undefined>(undefined);
  const timer = React.useRef(1);
  const [preloaded, setPreLoaded] = useState(false);
  const [showDebug, setShowDebug] = useState(false);
  const [nextElementSkip, setNextElementSkip] = useState(false);
  const loadToken = useAppSelector(selectLoadToken);
  const [showElm, setShowElm] = useState<{
    name: string,
    id: string,
    animation: t_animation, ref: React.RefObject<HTMLDivElement>
  } | undefined>(undefined);

  const load = async (elm: t_playElement) => {
    const data = await loadElement(firestore, elm);
    if (data) dispatch(setElementCash(data));
  };

  useEffect(() => {
    if (run && !preloaded && elements.length > 0) {
      Promise.all(elements.map(load))
        .then(() => console.log('Preload done'))
        .catch(() => {
          dispatch(setOffline(true));
        })
        .finally(() => setPreLoaded(true));
    }
  }, [preloaded, elements, run]);

  useEffect(() => {
    (async () => {
      if ('id' in params && params.id) {
        if (preShow) {
          // Preview Mode uses Playlist ID
          // TODO: Login Ohne Device ID ist aber nicht möglich derzeit...
          if (!loadToken) {
            dispatch(setPlayListId(params.id));
          }
        } else {
          await signIn(params.id);
          dispatch(setDeviceId(params.id));
        }
      }
    })();
  }, [params, dispatch, preShow, loadToken]);

  // I'm sure there's a smoother way to do this
  const skipable = async (element: t_playElement) => {
    let data : {id: string, data: t_elementType} | undefined;
    switch (element.type) {
      case 'Geburtstag':
        data = await loadElement(firestore, element);

        if (data) {
          const gbs = data.data as FullcalendarEvent[][];
          return gbs[0].length === 0 && element.value.skippable; // if no bd today && skipping is allowed
        }
        break;

      default:
        return false;
    }
  };

  useEffect(() => {
    if (aktiveElement) {
      skipable(aktiveElement).then((skip) => {
        setNextElementSkip(skip ?? false);

        if (skip) {
          console.info('Skipping Element');
          dispatch(next());
        } else {
          setShowElm({
            id: aktiveElement.id,
            name: aktiveElement.name,
            animation: aktiveElement.animation,
            ref: React.createRef<HTMLDivElement>()
          });
          if (aktiveElement.type === 'Video' && aktiveElement.value.waitForVideoToFinish) {
            dispatch(pause());
            timer.current = 0;
          } else {
            timer.current = aktiveElement.duration;
          }
        }
      });
    }
  }, [aktiveElement, dispatch]);

  useEffect(() => {
    if (preloaded && run) {
      if (timer.current <= 0) {
        dispatch(next());
      }
      timer.current -= 1000;
    }
  }, [trigger, preloaded, run]);

  useEffect(() => {
    if (preloaded && (noPlayList || elements.length === 0)) {
      setPreLoaded(false);
    }
  }, [noPlayList, preloaded, elements]);

  useEffect(() => {
    if (nextElm) {
      loadElement(firestore, nextElm, lastUpdate)
        .then((data) => {
          if (data) {
            dispatch(setElementCash(data)); // Change the lastUpdate value,
            // so it triggers this effect again but this time loadElement
            // should return undefined because lastUpdate is not 5min old
          }
        })
        .catch(() => {
          dispatch(setOffline(true));
        });
    }
  }, [nextElm, lastUpdate, dispatch, firestore]);

  if (!preloaded || noPlayList || elements.length === 0) {
    return (
      <CommuLinoLoading>
        <IframeMessages />
        {preShow && loadToken ? <div className={styles.errorMsg}>Login...</div>
          : (
            <>
              {noPlayList
                &&
                <div className={styles.errorMsg}>Keine Playlist für dieses Gerät angegeben.</div>}
              {!preloaded && elements.length === 0 && !noPlayList
                && (
                  <div className={styles.errorMsg}>Die Playlist für dieses Gerät hat keine
                    Element.
                  </div>
                )}
            </>
          )}
      </CommuLinoLoading>
    );
  }
  return (
    <>
      <Banner show={offline}>
        <Row>
          <FiInfo />
          {/* <div style={{ marginLeft: 5 }}>Offline</div> */}
        </Row>
      </Banner>
      {preShow && (
        <div style={{
          position: 'absolute',
          inset: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        >
          <div
            onClick={() => {
              dispatch(resume());
              dispatch(before());
            }}
            className={`${styles.button} ${styles.buttonLeft}`}
          >
            <FiChevronLeft style={{ fontSize: '3em' }} />
          </div>
          <div
            className={`${styles.button} ${styles.buttonRight}`}
            onClick={() => {
              dispatch(resume());
              timer.current = 0;
            }}
          >
            <FiChevronRight style={{ fontSize: '3em' }} />
          </div>
        </div>
      )}
      <Header skipping={nextElementSkip} />
      <div
        style={{
          position: 'relative',
          height: 'calc(100vh - 150px)'
        }}
      >
        <TransitionGroup>
          {preloaded && run && elements && showElm && (
            <CSSTransition
              key={showElm.id}
              nodeRef={showElm.ref}
              timeout={1000}
              classNames={`${showElm.animation}in`}
            >
              <div
                ref={showElm.ref}
                style={{
                  position: 'absolute',
                  inset: 0
                }}
              >
                <RenderPlayElement elmId={showElm.id} />
              </div>
            </CSSTransition>
          )}
        </TransitionGroup>
      </div>
    </>
  );
};

export default InfoScreen;
