import React from 'react';
import { FullcalendarEvent, TermineMode } from '@pixel-kraft/commulino-types';
import moment, { Moment } from 'moment';
import { Container } from 'components';
import styles from './Termine.module.css';
import noImgIcon from './icons/event.svg';

type t_props = {
  events: FullcalendarEvent[],
  day: TermineMode,
  id: string
}

const humanReadableTime = (time: Moment, format: string) => time.format(format);

const fullTime = (
  startD: string | null,
  endD: string | null,
  impendingView: boolean,
  isFullDay: boolean,
) => {
  const start = moment(startD);
  const end = moment(endD);

  isFullDay = isFullDay || (start.clone()
    .startOf('day')
    .isSame(start) && end.diff(start, 'days', true) === 1);

  if (isFullDay) { // Ganzer Tag
    return <div>Den ganzen Tag</div>;
  }

  return <div>Von {humanReadableTime(start, 'HH:mm')} bis {humanReadableTime(end, 'HH:mm')} Uhr</div>;
};

const headTime = (
  startD: string | null,
  endD: string | null,
  impendingView: boolean,
  isFullDay: boolean,
) => {
  const start = moment(startD);
  const end = moment(endD);

  isFullDay = isFullDay || (start.clone()
    .startOf('day')
    .isSame(start) && end.diff(start, 'days', true) === 1);
  const isToday = start.isSame(Date.now(), 'day');

  if (impendingView && isToday) { // Heute + Nächste Termine
    return <span className={styles.startDate}>Heute</span>;
  }
  if (impendingView && !isToday) { // Nicht Heute + Nächste Termine
    return <span className={styles.startDate}>{humanReadableTime(start, 'DD. MMMM')}</span>;
  }
  if (!impendingView && isFullDay) { // Ganztägig
    return <span className={styles.startDate}>Ganztägig</span>;
  }

  // Nur Uhrzeit
  return <span className={styles.startDate}>{humanReadableTime(start, 'HH:mm')}</span>;
};

const headlineMap: { [key in TermineMode]: string } = {
  [TermineMode.TODAY]: 'Heute',
  [TermineMode.TOMORROW]: 'Morgen',
  [TermineMode.SINGLE]: 'Nächster Termin',
  [TermineMode.FOLLOWING]: 'Nächste Termine',
};
const headLine = (day: TermineMode) => headlineMap[day];

const Termine = ({
  events,
  day,
  id,
}: t_props) => {
  if (day === TermineMode.SINGLE) {
    events = [events[0]];
  }

  return (
    <Container id={id}>
      <h1
        className={styles.title}
        style={{ marginTop: 0 }}
      >
        {headLine(day)}
      </h1>
      <div className={styles.terminWrapper}>
        {events.slice(0, 6).map((event) => (
          <div key={event.id} className={`${styles.termin} ${day === TermineMode.SINGLE ? styles.singeTermin : ''}`}>
            <div
              className={event.extendedProps.image
                ? styles.imageContainer
                : styles.imageContainerEmpty}
            >
              {event.extendedProps.image ? (
                  <img
                    className={styles.image}
                    alt='404'
                    src={event.extendedProps.image}
                  />
                )
                : (
                  <img
                    className={styles.icon}
                    alt='404'
                    src={noImgIcon}
                  />
                )}
            </div>
            <div className={styles.terminInnerContent}>
              <h2 className={styles.headline}>
                {headTime(
                  event.start,
                  event.end,
                  [TermineMode.SINGLE, TermineMode.FOLLOWING].includes(day),
                  !!event.allDay
                )}
                {event.title}
              </h2>
              <div className={styles.terminContent}>
                {fullTime(event.start, event.end, day === TermineMode.FOLLOWING, !!event.allDay)}
                {event.extendedProps.description && <div>{event.extendedProps.description}</div>}
              </div>
            </div>
          </div>
        ))}
        {events.length === 0 && <div className={styles.noTermine}>Keine Termine</div>}
      </div>
    </Container>
  );
};

export default Termine;
