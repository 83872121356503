import React, { HTMLProps, useEffect } from 'react';
import { firestore } from 'lib/firebase';
import {
  onSnapshot, doc, collection, query, orderBy
} from 'firebase/firestore';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  modifyElement,
  removeElement,
  selectDeviceId,
  selectPlayListId, setElements, setNoPlayList,
  setOffline,
  setPlaylist,
  setPlayListId, start
} from 'store/playlist/store';
import { Config_Color, t_PlayListData } from '@pixel-kraft/commulino-types';
import { triggerChange } from 'store/timer/stroe';
import { setColors } from 'store/config/store';
import moment from 'moment';
import 'moment/locale/de';
import useOnlineStatus from 'lib/useOnlineStatus';
import { t_playElement } from 'lib/types';

type t_props = HTMLProps<HTMLDivElement>

/**
 * A Listener Component for Firebase data.
 * @param props Div props
 */
const FirebaseListener = (props: t_props) => {
  const dispatch = useAppDispatch();
  const playListId = useAppSelector(selectPlayListId);
  const deviceId = useAppSelector(selectDeviceId);
  const online = useOnlineStatus();
  moment()
    .locale('de');

  useEffect(() => {
    if (deviceId) {
      return onSnapshot(doc(firestore, 'displays', deviceId), { includeMetadataChanges: true }, (snap) => {
        const device = snap.data();
        if (device && device.playListId) {
          dispatch(setPlayListId(snap.data()?.playListId));
        } else {
          dispatch(setNoPlayList(true));
        }
      });
    }
  }, [deviceId, dispatch]);

  useEffect(
    () => onSnapshot(doc(firestore, 'config', 'color'), { includeMetadataChanges: true }, (snapshot) => {
      if (snapshot.exists()) {
        dispatch(setColors(snapshot.data() as Config_Color));
        // dispatch(setOffline(snapshot.metadata.fromCache));
      } else {
        dispatch(setColors({
          mainColor: 'rgba(179,179,179,1)',
          subColor: 'rgba(0,82,204,1)'
        }));
      }
    }),
    [dispatch]
  );

  useEffect(() => {
    if (playListId) {
      const playListListener = onSnapshot(doc(firestore, 'config', 'infosystem', 'playLists', playListId), { includeMetadataChanges: true }, (snap) => {
        const playList = snap.data() as t_PlayListData;
        dispatch(setPlaylist(playList));
      });

      const elementsListener = onSnapshot(query(collection(firestore, 'config', 'infosystem', 'playLists', playListId, 'playElements'), orderBy('order')), { includeMetadataChanges: true }, (snap) => {
        const changes = snap.docChanges();
        changes.filter(({ type }) => type !== 'added')
          .forEach((change) => {
            switch (change.type) {
              case 'modified':
                dispatch(modifyElement({
                  ...change.doc.data() as t_playElement,
                  id: change.doc.id
                }));
                break;
              case 'removed':
                dispatch(removeElement(change.doc.id));
                break;
              default:
                throw new Error('Unknown Action');
            }
          });
        const elements: t_playElement[] = [];
        snap.forEach((elm) => {
          elements.push({
            ...elm.data() as t_playElement,
            id: elm.id
          });
        });
        dispatch(setElements(elements));
        dispatch(start());
      });
      return () => {
        playListListener();
        elementsListener();
      };
    }
  }, [playListId, dispatch]);

  useEffect(() => {
    let int: NodeJS.Timer;
    if (playListId) {
      int = setInterval(() => dispatch(triggerChange()), 1000);
    }
    return () => {
      if (int) {
        clearInterval(int);
      }
    };
  }, [dispatch, playListId]);

  useEffect(() => {
    dispatch(setOffline(!online));
  }, [dispatch, online]);

  return (<div {...props} />);
};
export default FirebaseListener;
