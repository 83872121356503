import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import playListReducer from './playlist/store';
import timerReducer from './timer/stroe';
import configReducer from './config/store';

export const store = configureStore({
  reducer: {
    playList: playListReducer,
    timer: timerReducer,
    config: configReducer,
  },
  middleware: (getDefaultMiddleware) => (process.env.NODE_ENV === 'development' ? getDefaultMiddleware().concat(logger) : getDefaultMiddleware())
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
