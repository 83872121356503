import React, { useEffect } from 'react';
import { selectOffline, t_videoCash } from 'store/playlist/store';
import { Container } from 'components';
import ReactPlayer from 'react-player/youtube';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resume } from 'store/timer/stroe';

type t_props = t_videoCash & { id: string };

const Video = ({
  id,
  waitForVideoToFinish,
  url
}: t_props) => {
  const dispatch = useAppDispatch();
  const finish = () => dispatch(resume());
  const offline = useAppSelector(selectOffline);

  // useEffect(() => () => {
  //     dispatch(resume());
  // }, [dispatch]);

  /* CHECK IF YOUTUBE SHORT */
  if (/youtube\.com\/shorts\/\.*/.test(url)) {
    const videoID = url.match(/youtube\.com\/shorts\/(\w*)/);

    if (videoID && videoID[1]) {
      url = `https://www.youtube.com/watch?v=${videoID[1]}`;
    }
  }

  return (
    <Container
      id={id}
      innerStyle={{
        width: '100vw',
        height: '100vh',
        paddingTop: 'unset',
        margin: 'unset',
        paddingBottom: 'unset',
        backgroundColor: 'black',
        top: '-150px',
      }}
    >
      <ReactPlayer
        muted // Must be set for chrome v^66 to autoplay
        height='100%'
        width='100%'
        pip={false}
        playing
        onBuffer={() => offline && finish()}
        onError={finish}
        onEnded={finish}
        url={url}
      />
      <div
        onClick={(e) => e.preventDefault()}
        style={{
          position: 'absolute',
          inset: 0
        }}
      />
    </Container>
  );
};
export default Video;
