import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'store/hooks';
import { selectAktive, selectNextElem } from 'store/playlist/store';
import logoSvg from 'config';
import Clock from 'components/Header/components/clock';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { t_animation } from '@pixel-kraft/commulino-types';
import styles from './header.module.css';
import '../../animation.css';

export function Header({ skipping } : { skipping: boolean }) {
  const active = useAppSelector(selectAktive);
  const next = useAppSelector(selectNextElem);
  const [title, setTitle] = useState('');
  const [elm, setElm] = useState<{
    name: string,
    id: string,
    animation: t_animation, ref: React.RefObject<HTMLSpanElement>
  } | undefined>(undefined);

  useEffect(() => {
    if (active) {
      setElm({
        name: active.name,
        id: active.id,
        animation: active.animation,
        ref: React.createRef<HTMLSpanElement>()
      });
    }
  }, [active]);

  // SKipping skippable Headline
  useEffect(() => {
    console.log(skipping);
    if (!skipping) {
      setTitle(active?.name ?? '');
    }
  }, [skipping, active]);

  if (!active || !next) {
    return null;
  }

  return (
    <div className={styles.header}>
      <Clock />
      {(active.id === next.id) ? (
        // SINGLE TITLE
        <h2 className={styles.title}>
          {active.name}
        </h2>
      ) : (
        // MULTIPLE TITLES INDICATES ANIMATION
        <h2
          className={styles.title}
          style={{ height: '100%' }}
        >
          <TransitionGroup>
            {elm
              && (
                <CSSTransition
                  key={elm.id}
                  timeout={1000}
                  nodeRef={elm.ref}
                  classNames={elm.animation}
                >
                  <span
                    ref={elm.ref}
                    className={styles.titleAnimation}
                  >
                    {title}
                  </span>
                </CSSTransition>
              )}

          </TransitionGroup>
        </h2>
      )}
      <img alt='logo' src={logoSvg.logoSvg} className={styles.logo} />
    </div>
  );
}
