import React from 'react';
import { ActivityIndicator, Row } from 'components/index';
import logo from 'assets/commulino-white.svg';
import styles from './CommuLinoLoading.module.css';

type t_props = {
  children?: JSX.Element | JSX.Element[] | string | false
}

const CommuLinoLoading = ({ children }: t_props) => (
  <div className={styles.loadingScreen}>
    <Row>
      <img
        src={logo}
        className={styles.logo}
        alt='CommuLino Logo'
        style={{ filter: 'invert(20%)' }}
      />
      <ActivityIndicator large style={styles.activeColor} />
    </Row>
    {children}
  </div>
);
export default CommuLinoLoading;
