import { Category, Post, PostData, Survey, i_lastOfCategory } from '@pixel-kraft/commulino-types';
import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where
} from 'firebase/firestore';

interface ParseInterface {
  (content: Post['content']): Post['content'];
}

const parseContent: ParseInterface = (content) => {
  try {
    return (typeof content === 'string' && content.startsWith('{')) ? JSON.parse(content) : content;
  } catch (err) {
    // If invalid JSON just return as text
    return content as string;
  }
};

const processPostData = async (
  firestore: Firestore,
  id: string,
  catId: string,
  postData: PostData
): Promise<Post> => {
  const cat = await getDoc(doc(firestore, 'categories', catId));
  const {
    content,
    ...data
  } = postData;
  const category = {
    ...cat.data() as Category,
    id: cat.id
  };
  let survey: Survey | undefined = undefined;
  if (data.survey) {
    survey = {
      id: data.survey,
      createdAt: 0,
      answers: [],
      multipleChoice: false,
      question: ''
    };
  }

  return {
    ...data,
    content: parseContent(content),
    category,
    survey,
    id
  };
};

export const getLatestPostInCategory = async (
  firestore: Firestore,
  catId: string,
  offset: i_lastOfCategory['value']['post'] = 0
): Promise<Post> => {
  const rawPosts = await getDocs(query(
    collection(firestore, 'posts'),
    where('category', '==', catId),
    where('date', '<=', Date.now()),
    orderBy('date', 'desc'),
    limit(offset + 1)
  ));
  if (rawPosts.size === 0) throw Error('No Posts!');
  const rawPost = rawPosts.docs[offset >= rawPosts.size ? rawPosts.size - 1 : offset];
  return processPostData(firestore, rawPost.id, catId, rawPost.data() as PostData);
};
