import React from 'react';
import { Post as PostData } from '@pixel-kraft/commulino-types';
import {Container} from 'components';
import { Content, Images, Title } from './componets';
import styles from './Post.module.css';

type t_props = {
  post: PostData,
  id: string,
}

const Post = ({
  post,
  id,
}: t_props) => (
  <Container id={id}>
    <Title post={post} />
    <div className={styles.contentWrapper}>
      <Content post={post} />
      <Images post={post} />
    </div>
  </Container>
);
export default Post;
