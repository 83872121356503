import React from 'react';
import styles from './ActivityIndicator.module.css';

interface Props {
	light?: boolean
	large?: boolean
	style?: string
}

const ActivityIndicator = ({ light, large, style }: Props) => (
  <div
    className={`${styles.indicator} ${large && styles.large} ${light && styles.light} ${style}`}
  />
);

export default ActivityIndicator;
