import React from 'react';
import styles from './Banner.module.css';

type t_props = {
  children: JSX.Element | JSX.Element[] | string,
  show: boolean
}

const Banner = ({
  show,
  children
}: t_props) => (
  <div
    className={`${styles.container}  ${!show ? styles.hidden : ''}`}
  >
    {children}
  </div>
);
export default Banner;
